import PHBG1 from '../../../assets/images/services/mascotas_background.webp'
import SSBAR1 from '../../../assets/images/services/service-sidebar-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import { useMounted } from '../../../hooks/use-mounted'
import { lapidas_api } from '../../../__api-resolvers__/lapidas-api'
import { useLocation } from "react-router-dom";
import { sort_object_of_objects_asc } from '../../../helpers/global_helpers'

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AvisoLegal = () => {
    let query = useQuery();
    const isMounted = useMounted();

    const [activeFaq, setActiveFaq] = useState({ id: null });


    return (
        <>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li>/</li>
                        <li>Aviso legal</li>
                    </ul>
                    <h2>Aviso legal</h2>
                </div>
            </section>

            <section className="service-details faq-one" style={{ paddingTop: 40 }}>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="aviso-legal__content">
                                <h3>AVISO LEGAL</h3>
                                <h4 style={{ fontWeight: 'bold' }} >TITULARIDAD:</h4>
                                <p>En cumplimiento de lo previsto en la Ley 34/2002 , de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico , se informa que el titular de este sitio web es la siguiente entidad:</p>
                                <p>ROSA ANA NOVOA DOMÍNGUEZ (en adelante GRUPO LÁPIDAS PEREIRO), con NIF 76996246C y domicilio en Avd. Castrelos 85, bajo. 36210 Vigo (Pontevedra).</p>
                                <p>Si desea ponerse en contacto con GRUPO LÁPIDAS PEREIRO, por favor hágalo aquí o en el teléfono (+34) 986 29 59 58</p>
                                <h4 style={{ fontWeight: 'bold' }}>ACEPTACIÓN DEL AVISO LEGAL:</h4>
                                <p>El acceso a este sitio web o su utilización en cualquier forma implica la aceptación de la totalidad de lo dispuesto en el presente Aviso Legal. GRUPO LÁPIDAS PEREIRO se reserva el derecho de modificar en cualquier momento el presente Aviso. En consecuencia, será responsabilidad de todo Usuario la lectura del Aviso Legal vigente en cada una de las ocasiones en que acceda, por lo que, si éste no está de acuerdo con cualquiera de las condiciones aquí dispuestas, deberá abstenerse respecto al uso del presente sitio web.</p>
                                <h4 style={{ fontWeight: 'bold' }}>OBJETO:</h4>
                                <p>El presente Aviso Legal recoge los términos y condiciones que regulan el acceso, navegación y uso del Sitio Web, el suministro y uso del contenido del Sitio Web, las responsabilidades derivadas del uso del Sitio Web y la prestación y/o contratación de los productos o servicios que, en su caso, pueden ser ofrecidos a través del Sitio Web. Sin perjuicio de cualquier disposición en contrario, lo anterior no impide que GRUPO LÁPIDAS PEREIRO pueda establecer condiciones particulares que regulen la utilización, prestación y/o contratación de productos o servicios que, en su caso, sean ofrecidos a los usuarios a través del Sitio Web. En todo caso, dichas condiciones particulares formarán parte integrante del presente Aviso Legal.</p>
                                <p>El mero acceso al Sitio Web, la cumplimentación de formularios, el envío de solicitudes de información, consultas, quejas, ofertas de contratación, curriculum vitae y, en general, cualquier acto de naturaleza similar a los anteriores realizados a través de los formularios y/ o buzones electrónicos existentes en el Sitio Web implicará, por su parte, la aceptación sin reservas de todas y cada una de las normas integrantes del presente Aviso Legal y la adquisición de la consideración de Usuario del Sitio Web. En consecuencia, Vd. debe leer atentamente y conocer el contenido del presente Aviso Legal.</p>
                                <p>En caso de ser ofrecidos, a través del Sitio Web, la utilización, prestación y/o contratación de productos o servicios, el hecho de ser utilizados y/o solicitados por el usuario implicará, también, la aceptación sin reservas de las condiciones particulares que , en su caso, han sido establecidos al efecto, y que formarán parte integrante del presente Aviso Legal.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default AvisoLegal
import PHBG1 from '../../../assets/images/services/mascotas_background.webp'
import SSBAR1 from '../../../assets/images/services/service-sidebar-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import { useMounted } from '../../../hooks/use-mounted'
import { lapidas_api } from '../../../__api-resolvers__/lapidas-api'
import { useLocation } from "react-router-dom";
import { sort_object_of_objects_asc } from '../../../helpers/global_helpers'

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PoliticaPrivacidad = () => {
    let query = useQuery();
    const isMounted = useMounted();

    const [activeFaq, setActiveFaq] = useState({ id: null });

    const [data_mascotas, setdata_mascotas] = useState(null);

    return (
        <>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li>/</li>
                        <li>Política de privacidad</li>
                    </ul>
                    <h2>Política de privacidad</h2>
                </div>
            </section>

            <section className="service-details faq-one" style={{ paddingTop: 40 }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="aviso-legal__content">
                                <h4>1. IDENTIFICACIÓN Y DATOS DE CONTACTO DEL RESPONSABLE</h4>
                                <p>Rosa Ana Novoa Domínguez, domiciliada en Avd. Castrelos 85, bajo. 36210 Vigo (Pontevedra), con NIF 76996246C,
                                    y teléfono (+34) 986 29 59 58 (en adelante, “GRUPO LÁPIDAS PEREIRO” o la “EMPRESA”) es el responsable del tratamiento
                                    de sus datos personales. En la presente política de privacidad se facilita la información sobre el uso que realiza
                                    la EMPRESA de sus datos personales en su condición de usuario de la web (la "Web").</p>
                                <p>La presente Política de Privacidad tiene por objeto facilitar información sobre los derechos que le asisten en
                                    virtud del Reglamento General de Protección de Datos (“RGPD”). Si tiene alguna duda relativa al tratamiento de
                                    sus datos personales, contacte con la EMPRESA en la siguiente dirección: <a href="mailto:info@grupolapidaspereiro.com">info@grupolapidaspereiro.com</a></p>
                                <p>Asimismo, le informamos de que GRUPO LÁPIDAS PEREIRO cuenta con un Delegado de Protección de Datos, con quien se podrá
                                    poner en contacto a través de la siguiente dirección: <a href="mailto:info@grupolapidaspereiro.com">info@grupolapidaspereiro.com</a></p>

                                <h4>2. INFORMACIÓN NECESARIA Y ACTUALIZADA</h4>
                                <p>Todos los campos que aparecen señalados con un asterisco (*) en los formularios de la Web serán de cumplimentación obligada,
                                    de tal modo que la omisión de alguno de ellos podría comportarse la imposibilidad de que se le puedan facilitar los servicios
                                    o información solicitada.</p>
                                <p>Debe proporcionar información verídica, para que la información facilitada esté siempre actualizada y no contenga errores,
                                    deberá comunicar a la EMPRESA a la mayor brevedad posible, las modificaciones y rectificaciones de sus datos de carácter personal
                                    que se vayan produciendo a través de un correo electrónico a la dirección: <a href="mailto:info@grupolapidaspereiro.com">info@grupolapidaspereiro.com</a></p>
                                <p>Asimismo, al hacer “click” en el botón “Acepto” (o equivalente) incorporado en los citados formularios, declara que la información
                                    y los datos que en ellos ha facilitado son exactos y veraces.</p>

                                <h4>3. INFORMACIÓN GENERAL: DESCRIPCIÓN DE LA INFORMACIÓN CONTENIDA EN LA POLÍTICA DE PRIVACIDAD</h4>
                                <p>En la presente política de privacidad encontrará una tabla identificando, por cada uno de los diferentes tratamientos de datos
                                    realizados por la EMPRESA, la siguiente información:</p>
                                <ul>
                                    <li>Las finalidades del tratamiento de sus datos personales, esto es, el motivo por el cual la EMPRESA trata sus datos personales.</li>
                                    <li>Las bases legales que permiten el tratamiento de sus datos por parte de la EMPRESA para cada una de las finalidades indicadas.</li>
                                    <li>La posible comunicación de sus datos a terceros, así como la causa de dicha comunicación. A estos efectos, le informamos de que
                                        no cedemos sus datos personales a terceros salvo que exista una obligación legal. Por otra parte, podrán tener acceso a sus datos
                                        personales los encargados del tratamiento de la EMPRESA, es decir, los prestadores de servicios que tendrán que acceder a sus datos
                                        personales para el desarrollo de sus funciones. Los prestadores de servicios que acceden a sus datos personales, con carácter general,
                                        se dedican a los sectores de sistemas de información y tecnología. En la tabla que figura a continuación se indicarán, en su caso,
                                        aquellos otros sectores a los que se dediquen otros posibles prestadores de servicios de la EMPRESA que deban acceder a sus datos
                                        personales.</li>
                                    <li>Le informamos de que puede solicitar mayor detalle de información respecto de los destinatarios de sus datos enviando un correo
                                        electrónico a la dirección: <a href="mailto:info@grupolapidaspereiro.com">info@grupolapidaspereiro.com</a>, Indicando el tratamiento concreto sobre quiénes destinatarios querría información.</li>
                                    <li>La existencia de posibles transferencias internacionales de datos. En caso de que desee solicitar una copia de las cláusulas
                                        contractuales referidas en la tabla abajo indicada, por favor, póngase en contacto con GRUPO LÁPIDAS PEREIRO en el siguiente
                                        correo electrónico para cursar su petición: <a href="mailto:info@grupolapidaspereiro.com">info@grupolapidaspereiro.com</a></li>
                                    <li>El plazo de conservación de los datos que nos facilita. Asimismo, le informamos de que sus datos se perdieron bloqueados para
                                        la atención de reclamaciones judiciales, administrativas o fiscales, durante los plazos que resulten de la legislación aplicable.</li>
                                </ul>

                                <h4>4. EJERCICIO DE SUS DERECHOS</h4>
                                <p>Le informamos de que podrá ejercer los siguientes derechos:</p>
                                <ul>
                                    <li>El derecho de acceso a sus datos personales para saber correctamente están siendo objeto de tratamiento y las operaciones
                                        de tratamiento llevadas a cabo con ellos.</li>
                                    <li>Derecho de rectificación de cualquier dato personal inexacto.</li>
                                    <li>Derecho de supresión de sus datos personales, cuando esto sea posible.</li>
                                    <li>Derecho a solicitar la limitación del tratamiento de sus datos personales cuando la exactitud, la legalidad o la necesidad
                                        del tratamiento de los datos resulte dudosa, en cuyo caso, podremos conservarlos para el ejercicio o la defensa de reclamaciones.</li>
                                    <li>Derecho a la portabilidad de sus datos personales, cuando la base legal que nos habilite para su tratamiento sea la relación
                                        contractual o el consentimiento.</li>
                                    <li>Derecho de oposición al tratamiento de sus datos personales, cuando la base legal que nos habilite para su tratamiento sea el
                                        interés legítimo. A estos efectos, dejaremos de tratar sus datos salvo que tengamos un interés legítimo imperioso o para la
                                        formulación, el ejercicio o la defensa de reclamaciones.</li>
                                    <li>Derecho a revocar su consentimiento en cualquier momento.</li>
                                </ul>
                                <p>Podrá ejercitar sus derechos en cualquier momento y de forma gratuita dirigiendo un correo electrónico a:
                                    <a href="mailto:info@grupolapidaspereiro.com">info@grupolapidaspereiro.com</a> indicando el derecho que desea ejercitar y sus datos
                                    identificativos.</p>
                                <p>Si considera que no hemos tratado sus datos personales adecuadamente puede contactar con el Delegado de Protección de Datos:
                                    <a href="mailto:info@grupolapidaspereiro.com">info@grupolapidaspereiro.com</a>. No obstante, le informamos de que tiene derecho a presentar
                                    una reclamación ante la Agencia Española de Protección de Datos si considera que se ha cometido una infracción de la legislación
                                    en materia de protección de datos con respecto al tratamiento de sus datos personales.</p>

                                <h4>5. COOKIES</h4>
                                <p>GRUPO LÁPIDAS PEREIRO utiliza únicamente dispositivos de almacenamiento y recuperación de datos ('Cookies') cuando el usuario haya dado
                                    su consentimiento previo para ello de acuerdo con lo que se indica en la ventana emergente del navegador del usuario cuando acceda por
                                    primera vez a la Web y en los demás términos y condiciones que se indican en la Política de Cookies de GRUPO LÁPIDAS PEREIRO que todo
                                    usuario debe conocer.</p>

                                <h4>6. MEDIDAS DE SEGURIDAD</h4>
                                <p>GRUPO LÁPIDAS PEREIRO adopta los niveles de seguridad requeridos por el RGPD adecuados a la naturaleza de los datos que son objeto de
                                    tratamiento en cada momento. No obstante lo anterior, la seguridad técnica en un medio como Internet no es inexpugnable y pueden existir
                                    actuaciones dolorosas de terceros, si bien Garrigues pone todos los medios a su alcance para evitar dichas actuaciones.</p>

                                <h4>7. MENORES</h4>
                                <p>Los menores de edad no podrán usar los servicios disponibles a través de la Web sin la previa autorización de sus padres, tutores o
                                    representantes legales, quienes serán los únicos responsables de todos los actos realizados a través de la Web por los menores a su cargo,
                                    incluyendo la cumplimentación de los formularios con los datos personales de dichos menores y la marcación, en su caso, de las casillas
                                    que los acompañan.</p>

                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default PoliticaPrivacidad
import axios from 'axios';

class LapidasAPI {

    getContentLapidas() {

        return new Promise((resolve, reject) => {

            try {


                axios.get(encodeURI(`https://api.grupolapidaspereiro.com/content/lapidas`),
                    {
                        auth: {
                            username: process.env.REACT_APP_USER_ACCESS,
                            password: process.env.REACT_APP_PASSWORD_ACCESS
                        }
                    }).then(res => {

                        console.log("resultado api getContentLapidas ", res.data);

                        if (res.data.result.localeCompare("success") === 0) {
                            resolve(res.data.data);
                        } else if (res.data.result.localeCompare("error") === 0) {
                            reject(new Error(res.data.data));
                            return;
                        }

                    }).catch(error => {

                        if (error.response) {
                            // Request made and server responded
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);

                            reject(new Error(error.response.data.data));
                            return;

                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                            reject(new Error(error.request));
                            return;
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                            reject(new Error(error.message));
                            return;
                        }

                    });

            } catch (err) {
                console.error('[Auth Api]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    }


    getContentCarrucelLapidasHome() {

        return new Promise((resolve, reject) => {

            try {


                axios.get(encodeURI(`https://api.grupolapidaspereiro.com/content/carrucel_lapidas_home`),
                    {
                        auth: {
                            username: process.env.REACT_APP_USER_ACCESS,
                            password: process.env.REACT_APP_PASSWORD_ACCESS
                        }
                    }).then(res => {

                        console.log("resultado api getContentCarrucelLapidasHome ", res.data);

                        if (res.data.result.localeCompare("success") === 0) {
                            resolve(res.data.data);
                        } else if (res.data.result.localeCompare("error") === 0) {
                            reject(new Error(res.data.data));
                            return;
                        }

                    }).catch(error => {

                        if (error.response) {
                            // Request made and server responded
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);

                            reject(new Error(error.response.data.data));
                            return;

                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                            reject(new Error(error.request));
                            return;
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                            reject(new Error(error.message));
                            return;
                        }

                    });

            } catch (err) {
                console.error('[Auth Api]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    }


    sendNewEmail(
        nombres_destinatarios,
        destinatarios,
        asunto,
        cuerpo_msg,
        titulo_msg,
        subitulo_msg,
        nombre_remitente,
        email_smtp,
        reply_to,
        nombre_reply_to,
        img_mensaje = null,
        copias_correo = null,
        entidad = null,
        imagen_anagrama_empresa = null,
        entidad_url = null,
        button_text = null,
        button_action = null,
        bcc = null
    ) {

        return new Promise((resolve, reject) => {

            try {

                let formData = new FormData();
                formData.append('nombres_destinatarios', nombres_destinatarios);
                formData.append('destinatarios', destinatarios);
                formData.append('asunto', asunto);
                formData.append('cuerpo_msg', cuerpo_msg);
                formData.append('titulo_msg', titulo_msg);
                formData.append('subitulo_msg', subitulo_msg);
                formData.append('nombre_remitente', nombre_remitente);
                formData.append('email_smtp', email_smtp);
                formData.append('reply_to', reply_to);
                formData.append('nombre_reply_to', nombre_reply_to);

                formData.append('copias_correo', copias_correo);
                if (img_mensaje) {
                    formData.append('img_mensaje', img_mensaje);
                }
                if (entidad) {
                    formData.append('entidad', entidad);
                }
                if (imagen_anagrama_empresa) {
                    formData.append('imagen_anagrama_empresa', imagen_anagrama_empresa);
                }
                if (entidad_url) {
                    formData.append('entidad_url', entidad_url);
                }
                if (button_text) {
                    formData.append('button_text', button_text);
                }
                if (button_action) {
                    formData.append('button_action', button_action);
                }
                if (bcc) {
                    formData.append('bcc', bcc);
                }



                axios.post(encodeURI(`https://api-intranet.iiysi.com/add_email`),
                    formData,
                    {
                        headers: {
                            'x-api-key': 'K5b9]y[.,V!njL4k6FQ>C2',
                            'Content-Type': 'multipart/form-data',
                        }
                    }).then(res => {
                        //console.log(res);
                        console.log("resultado api sendNewEmail ", res.data);

                        if (res.data.result.localeCompare("success") === 0) {
                            resolve(res.data.data);
                        } else if (res.data.result.localeCompare("error") === 0) {
                            reject(new Error(res.data.data));
                            return;
                        }

                    }).catch(error => {

                        if (error.response) {
                            // Request made and server responded
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);

                            reject(new Error(error.response.data.data));
                            return;

                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                            reject(new Error(error.request));
                            return;
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                            reject(new Error(error.message));
                            return;
                        }

                    });

            } catch (err) {
                console.error('[Auth Api]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    }


}

export const lapidas_api = new LapidasAPI();
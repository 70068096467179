import PHBG1 from '../../../assets/images/services/mascotas_background.webp'
import SSBAR1 from '../../../assets/images/services/service-sidebar-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import { useMounted } from '../../../hooks/use-mounted'
import { lapidas_api } from '../../../__api-resolvers__/lapidas-api'
import { useLocation } from "react-router-dom";
import { sort_object_of_objects_asc } from '../../../helpers/global_helpers'

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CookiesAgreement = () => {
    let query = useQuery();
    const isMounted = useMounted();

    const [activeFaq, setActiveFaq] = useState({ id: null });

    const [data_mascotas, setdata_mascotas] = useState(null);

    return (
        <>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li>/</li>
                        <li>Cookies</li>
                    </ul>
                    <h2>Cookies</h2>
                </div>
            </section>

            <section className="service-details faq-one" style={{ paddingTop: 40 }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="aviso-legal__content">
                                <h4>Política de Cookies</h4>
                                <p>Nuestro sitio web www.grupolapidaspereiro.com (el "Sitio Web") utiliza una tecnología denominada "cookies" con la finalidad de
                                    poder recabar información acerca del uso del Sitio Web. Le informamos de que podemos utilizar cookies con la finalidad de facilitar
                                    su navegación a través del Sitio Web, distinguirle de otros usuarios y analizar sus hábitos de navegación dentro del Sitio Web.</p>
                                <p>La presente política de cookies tiene por finalidad informarle de manera clara y precisa sobre las cookies que se utilizan en nuestro
                                    Sitio Web (la "Política de Cookies").</p>
                                <h4>¿Qué son las cookies?</h4>
                                <p>Una cookie es un archivo que se descarga en su equipo (ordenador o dispositivo móvil) con la finalidad de almacenar datos que podrán
                                    ser actualizados y recuperados por la entidad responsable de su instalación.</p>
                                <p>La información recabada a través de las cookies puede incluir la dirección IP, fecha y hora de visitas al Sitio Web, las páginas
                                    visionadas dentro del Sitio Web, el tiempo que ha estado en nuestro Sitio Web y los sitios visitados justo antes y después del mismo y
                                    otros datos de navegación. Así, las cookies permiten obtener información sobre sus hábitos de navegación.</p>
                                <h4>Tipo de cookies que se usan en el Sitio Web</h4>
                                <p>Por una parte, el titular de este Sitio Web utilizará cookies propias técnicas para las que no es necesaria la obtención de su
                                    consentimiento por estar excluidas del ámbito de aplicación del artículo 22.2 de la Ley 34/2002, de 11 de julio, de servicios de la
                                    sociedad de la información y de comercio electrónico.</p>
                                <p>Por otra parte, el titular de este Sitio Web utilizará cookies propias técnicas para las que no es necesaria la obtención de su
                                    consentimiento por estar excluidas del ámbito de aplicación del artículo 22.2 de la Ley 34/2002, de 11 de julio, de servicios de la
                                    sociedad de la información y de comercio electrónico.</p>
                                <h4>Cookies propias</h4>
                                <h4>Cookies analíticas</h4>
                                <p>Nuestro Sitio Web utiliza el sistema de medición de audiencia Google Analytics, una herramienta de análisis web de Google LLC.
                                    ("Google"), con domicilio en 1600 Amphitheatre Parkway, Mountain View, California 94043 (EE. UU.), que nos permite conocer cómo
                                    interactúan los usuarios de nuestro Sitio. La instalación de las cookies analíticas de este servicio junto con nuestros archivos de
                                    registro del servidor, nos permite conocer el número total de usuarios que visitan nuestro Sitio Web y aquellas partes del mismo que
                                    gozan de mayor popularidad. Gracias a ellas obtenemos una información que puede ayudarnos a mejorar la navegación y dar un mejor
                                    servicio a usuarios y clientes. Le informamos de que la transferencia internacional a Google se ampara en la formalización de las
                                    cláusulas contractuales tipo aprobadas por la Comisión Europea.</p>
                                <p>A continuación puede ver el listado detallado de Cookies que instala el servicio Google Analytics a través de nuestro Sitio Web:</p>
                                <ul>
                                    <li>GOOGLE ANALYTICS (_GA):</li>
                                    <p>Finalidad: Se utiliza para identificar y distinguir a un usuario. Almacenan un identificador de cliente único (ID de cliente),
                                        que se genera aleatoriamente. Se utiliza para calcular las interacciones de los usuarios (visitas, los datos de usuarios, sesiones y
                                        campañas), con el fin de optimizar los servicios que ofrecen.</p>
                                    <p>Duración: 2 años</p>
                                    <li>GOOGLE ANALYTICS (_GID)</li>
                                    <p>Finalidad: Se utiliza para identificar al mismo usuario durante un único día.</p>
                                    <p>Duración: 24 horas</p>
                                    <li>GOOGLE ANALYTICS (_GAT)</li>
                                    <p>Finalidad: Se utiliza para diferenciar entre los diferentes objetos de seguimiento creados en la sesión. La cookie se actualiza
                                        cada vez que envía los datos a Google Analytics.</p>
                                    <p>Duración: 1 minuto</p>
                                </ul>
                                <h4>Rechazo y revocación del consentimiento</h4>
                                <p>Puede rechazar el uso de cookies en este sitio web y revocar su consentimiento utilizando la siguiente herramienta CONFIGURADOR DE
                                    COOKIES.</p>
                                <h4>Eliminación de cookies</h4>
                                <p>En cualquier caso, le informamos de que puede eliminar, bloquear o deshabilitar las cookies en cualquier momento modificando la
                                    configuración de su navegador, que le permitirá rechazar la instalación de todas las cookies o de algunas de ellas. Si elimina todas
                                    las cookies de este sitio web, a través de su navegador, borrará también las opciones de configuración que haya elegido sobre nuestras
                                    cookies (aceptación, rechazo o revocación de su uso). A continuación, podrá encontrar información sobre cómo gestionar el uso de
                                    cookies en función del navegador utilizado:</p>
                                <ul>
                                    <li>FIREFOX: <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a></li>
                                    <li>CHROME: <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es</a></li>
                                    <li>SAFARI: <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">https://support.apple.com/es-es/guide/safari/sfri11471/mac</a></li>
                                    <li>INTERNET EXPLORER: <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
                                    <li>MICROSOFT EDGE: <a href="https://privacy.microsoft.com/es-es/windows-10-microsoft-edge-and-privacy">https://privacy.microsoft.com/es-es/windows-10-microsoft-edge-and-privacy</a></li>
                                </ul>
                                <h4>Cambios en la Política de Cookies</h4>
                                <p>Es posible que actualicemos la Política de Cookies de nuestro Sitio Web. Por ello, le recomendamos revisar esta política cada vez que
                                    acceda a nuestro Sitio Web con el objetivo de estar adecuadamente informado sobre cómo y para qué usamos las cookies.</p>
                                <h4>Política de Privacidad</h4>
                                <p>Puede leer nuestra política de privacidad para una mayor información sobre el tratamiento de datos personales.</p>
                                <h4>Contacto</h4>
                                <p>Si tuviera alguna duda, comentario o sugerencia sobre la Política de Cookies, por favor escriba a: info@grupolapidaspereiro.com</p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default CookiesAgreement
import PHBG1 from '../../../assets/images/backgrounds/background_services-v2.webp'
import { Link } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import { useMounted } from '../../../hooks/use-mounted';
import { lapidas_api } from '../../../__api-resolvers__/lapidas-api';

const Service = () => {

    const isMounted = useMounted();
    const [data_lapidas, setdata_lapidas] = useState(null);

    const getDataFromSystem = useCallback(async () => {
        try {
            const data = await lapidas_api.getContentLapidas();
            console.log("data de lapidas ", data);

            if (isMounted()) {
                setdata_lapidas(data);
            }

        } catch (err) {
            console.error(err);
        }
    }, [isMounted]);

    useEffect(() => {
        getDataFromSystem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg background_image_services" style={{ backgroundImage: `url(${PHBG1})`, backgroundPositionY: 'center' }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li>/</li>
                        <li><span>Lápidas</span></li>
                    </ul>
                    <h2>Lápidas</h2>
                </div>
            </section>

            <section className="feature-one custom_variant_lapidas_web">
                <img src="assets/images/shapes/feature-shape-1-1_v2.png" alt="" className="feature-one__shape-1" />
                <img src="assets/images/shapes/feature-shape-1-2_v2.png" alt="" className="feature-one__shape-2" />
                <img src="assets/images/shapes/feature-shape-1-3_v2.png" alt="" className="feature-one__shape-3" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="block-title text-left">
                                <p style={{ opacity: 0 }}>Pereiro</p>
                                <h2>La Lápida</h2>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <p className="block-text">
                                Una lápida no deja de ser un resumen de una vida y la biografía de uno , y en ocasiones de los gustos personales, de la persona que ha fallecido.
                                Para honrar a ese familiar, resulta adecuado que confíes en personas con experiencia en el sector que te ofrezcan un resultado eficaz que permita convertir la tumba en un espacio más personal y entrañable.
                                Personaliza las lápidas en el tamaño que quieras, las inscripciones que salgan de tu corazón y una escultura única
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="service-page pt-120 pb-60">
                <div className="container">
                    {!data_lapidas ?
                        <div className="progress">
                            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '75%' }}></div>
                        </div>
                        :
                        <div className="row">
                            {data_lapidas.map((lapida) =>
                                <div
                                    key={lapida.id}
                                    className="col-lg-4 col-md-6 mb-60"
                                >
                                    <div className="service-one__card">
                                        <div className="service-one__image">
                                            <img src={lapida.image_list} alt="" />
                                        </div>
                                        <div className="service-one__content">
                                            <h3><Link to={`/service-details?lapida=${lapida.uuid_servicio}`}>{lapida.nombre}</Link></h3>
                                            <p>
                                                {lapida.contenido}
                                            </p>
                                            <Link to={`/service-details?lapida=${lapida.uuid_servicio}`} className="pylon-icon-right-arrow service-one__link"></Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </section>


            <section className="feature-one custom_variant_lapidas_web">
                <img src="assets/images/shapes/feature-shape-1-1_v2.png" alt="" className="feature-one__shape-1" />
                <img src="assets/images/shapes/feature-shape-1-2_v2.png" alt="" className="feature-one__shape-2" />
                <img src="assets/images/shapes/feature-shape-1-3_v2.png" alt="" className="feature-one__shape-3" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <picture>
                                <source
                                    media="(max-width: 767px)"
                                    sizes="(max-width: 565px) 100vw, 565px"
                                    srcSet="
                                assets/images/resources/diversas_opaciones_fabricacion_gtkkfo/diversas_opaciones_fabricacion_gtkkfo_ar_1_1,c_fill,g_auto__c_scale,w_200.webp 200w,
                                assets/images/resources/diversas_opaciones_fabricacion_gtkkfo/diversas_opaciones_fabricacion_gtkkfo_ar_1_1,c_fill,g_auto__c_scale,w_565.webp 565w"/>
                                <img
                                    sizes="(max-width: 1675px) 40vw, 670px"
                                    srcSet="
                                assets/images/resources/diversas_opaciones_fabricacion_gtkkfo/diversas_opaciones_fabricacion_gtkkfo_c_scale,w_308.webp 308w,
                                assets/images/resources/diversas_opaciones_fabricacion_gtkkfo/diversas_opaciones_fabricacion_gtkkfo_c_scale,w_670.webp 670w"
                                    src="assets/images/resources/diversas_opaciones_fabricacion_gtkkfo/diversas_opaciones_fabricacion_gtkkfo_c_scale,w_670.webp"
                                    alt=""
                                    className="how-works__image"
                                />
                            </picture>
                        </div>
                        <div className="col-lg-7">
                            <div className="how-works__content wow fadeInRight" data-wow-duration="1500ms">
                                <div className="block-title text-left">
                                    <p>A tener en cuenta...</p>
                                    <h2>Diversas opciones de fabricación</h2>
                                </div>
                                <p className="block-text">
                                    Solo debes tener en cuenta las diversas opciones de lápidas disponibles para que te sea más fácil encontrar el producto perfecto.
                                    Confiando en profesionales del sector, te será más fácil conocer las respuestas a tus dudas y, posiblemente, llevar tu propio diseño para que la lápida resultante colme todas tus expectativas.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Service
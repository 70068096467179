
import BGPH_FINAL_1 from '../../assets/images/main-slider/main-slider-lap-1-1-v2.webp'
import BGPH_FINAL_2 from '../../assets/images/main-slider/main-slider-lap-1-2-v2.webp'
import BGPH_FINAL_3 from '../../assets/images/main-slider/main-slider-lap-1-3-v2.webp'
import TrustBG1 from '../../assets/images/shapes/trust-bg-1-1.png'
import FunFactBG1 from '../../assets/images/backgrounds/background_home_1894x1266.webp'
import ActionBG1 from '../../assets/images/backgrounds/background_1500x1004.webp'
import AnimatedNumber from "animated-number-react"
import { Navigation, Autoplay, Pagination, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom'
import React, { useState, useEffect, useCallback } from 'react'
import { useMounted } from '../../hooks/use-mounted'
import { lapidas_api } from '../../__api-resolvers__/lapidas-api'
import { format_integer_thousands_to_point } from '../../helpers/global_helpers'

const Main = () => {

    const isMounted = useMounted();
    const [data_carrucel_home_lapidas, setdata_carrucel_home_lapidas] = useState(null);

    const [loanAmt, setloanAmt] = useState(0)
    const [loanMonth, setloanMonth] = useState(0)
    const [interestRate, setinterestRate] = useState(0)
    const [emiVal, setEmival] = useState()
    const [TotalVal, setTotalVal] = useState()
    const [slider1, setSlider1] = useState(1000)
    const [slider2, setSlider2] = useState(1)


    const [data_lapidas, setdata_lapidas] = useState([]);




    useEffect(() => {
        if (slider1 > 0 && slider2 > 0) {
            emiCalculate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slider1, slider2])

    const emiCalculate = () => {
        const interestRate = 15;
        const interestRatePercent = parseInt(interestRate, 10) / 100;
        const totalPay = slider1 * interestRatePercent + parseInt(slider1, 10);
        const monthlyPay = totalPay / parseInt(slider2, 10);

        setEmival(monthlyPay)
        setTotalVal(totalPay)

    }


    const getDataFromSystem = useCallback(async () => {
        try {
            const data = await lapidas_api.getContentCarrucelLapidasHome();
            const data_lapidas = await lapidas_api.getContentLapidas();
            console.log("data de carrucel ", data);
            console.log("data de lapidas_home ", data_lapidas);

            if (isMounted()) {
                setdata_carrucel_home_lapidas(data);
                setdata_lapidas(data_lapidas);
            }

        } catch (err) {
            console.error(err);
        }
    }, [isMounted]);

    useEffect(() => {
        getDataFromSystem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const open_url = (url_to_open) => {
        window.open(url_to_open, "_blank");
    }

    return (
        <>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>

            <section className="main-slider">
                <Swiper
                    className="swiper-container thm-swiper__slider"
                    effect={"fade"}
                    speed={1500}
                    modules={[Navigation, Autoplay, EffectFade]}
                    slidesPerView={1}
                    loop={true}
                    navigation={{
                        nextEl: "#main-slider__swiper-button-next",
                        prevEl: "#main-slider__swiper-button-prev"
                    }}
                    autoplay={{ delay: 5000 }}
                >
                    <div className="swiper-wrapper">
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="image-layer" style={{ backgroundImage: `url(${BGPH_FINAL_1})` }}></div>
                                <div className="container contenido_carrucel">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6">
                                            <p style={{ opacity: 0 }}>Simple & Secures</p>
                                            <h2>LÁPIDAS CON ESCULTURAS</h2>
                                            <Link
                                                to={`service-details?lapida=${data_lapidas.find((lapida) => lapida.nombre.localeCompare('LÁPIDAS CON ESCULTURA') === 0)?.uuid_servicio}`}
                                                className={`thm-btn ${data_lapidas.length > 0 ? '' : 'disable-link'}`}
                                            >
                                                Más información
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="image-layer" style={{ backgroundImage: `url(${BGPH_FINAL_2})` }}></div>
                                <div className="container contenido_carrucel">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6">
                                            <p style={{ opacity: 0 }} >Simple & Secure</p>
                                            <h2>LÁPIDAS CON GRABADO LÁSER</h2>
                                            <Link
                                                to={`service-details?lapida=${data_lapidas.find((lapida) => lapida.nombre.localeCompare('LÁPIDAS LASER') === 0)?.uuid_servicio}`}
                                                className={`thm-btn ${data_lapidas.length > 0 ? '' : 'disable-link'}`}
                                            >
                                                Más información
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="image-layer" style={{ backgroundImage: `url(${BGPH_FINAL_3})` }}></div>
                                <div className="container contenido_carrucel">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6">
                                            <p style={{ opacity: 0 }}>Simple & Secures</p>
                                            <h2>LÁPIDAS CON CRUZ</h2>
                                            <Link
                                                to={`service-details?lapida=${data_lapidas.find((lapida) => lapida.nombre.localeCompare('LÁPIDAS CON CRUZ') === 0)?.uuid_servicio}`}
                                                className={`thm-btn ${data_lapidas.length > 0 ? '' : 'disable-link'}`}
                                            >
                                                Más información
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </div>


                    <div className="main-slider__nav">
                        <div className="swiper-button-prev" id="main-slider__swiper-button-next"><i className="pylon-icon-left-arrow"></i></div>
                        <div className="swiper-button-next" id="main-slider__swiper-button-prev"><i className="pylon-icon-right-arrow"></i></div>
                    </div>

                </Swiper>

            </section>

            <section className="feature-one custom_variant">
                <img src="assets/images/shapes/feature-shape-1-1_v2.png" alt="" className="feature-one__shape-1" />
                <img src="assets/images/shapes/feature-shape-1-2_v2.png" alt="" className="feature-one__shape-2" />
                <img src="assets/images/shapes/feature-shape-1-3_v2.png" alt="" className="feature-one__shape-3" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="block-title text-left">
                                <p>Grupo Lápidas Pereiró</p>
                                <h2>La empresa</h2>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <p className="block-text">
                                En Grupo Lápidas Pereiro  estamos  dedicados  en la elaboración de lápidas a medida y las adaptamos a las circunstancias de cada cliente.
                                Con ellas podemos honrar al difunto de una manera única, que permite rememorar y mostrar una parte de su personalidad. Trabajamos con materiales resistentes, duraderos y de gran belleza.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="feature-one__box">
                                <img src="assets/images/icons/canasta.webp" alt="" className="pylon-icon-assets" style={{ zIndex: 10, width: 70, height: 70, marginRight: 20 }} />
                                <p>
                                    Trabajamos con precios asequibles que se adaptan a todos los trabajos.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="feature-one__box">
                                <img src="assets/images/icons/usuario.webp" alt="" className="pylon-icon-assets" style={{ zIndex: 10, width: 70, height: 70, marginRight: 20 }} />
                                <p>
                                    Todos nuestros trabajos son revisados durante su fabricación y instalación.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="feature-one__box">
                                <img src="assets/images/icons/conectividad.webp" alt="" className="pylon-icon-assets" style={{ zIndex: 10, width: 70, height: 70, marginRight: 20 }} />
                                <p>
                                    Trabajamos para el cliente particular y empresas funerarias.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="trusted-company">
                <div className="trusted-company__bg" style={{ backgroundImage: `url(${TrustBG1})` }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="trusted-company__content">
                                <div className="block-title text-left">
                                    <p>Materiales</p>
                                    <h2>Materiales de diversas calidades</h2>
                                </div>
                                <div className="trusted-company__image">
                                    <p>Disponemos de un amplio catálogo con diversos mármoles y lápidas entre los que podemos elegir.
                                        Además, ofrecemos un servicio de asesoramiento. Está pensado para que cada cliente consiga justo el diseño que busca y, de esta manera, pueda honrar a su ser querido tal y como se merece.
                                        Encontrarás un servicio basado en la calidad, el buen trabajo de los materiales que roza un arte muy refinado.
                                    </p>
                                    <br />
                                    <p>
                                        En Grupo Lápidas Pereiro  queremos seguir creciendo y creemos que la mejor forma de hacerlo es apoyando y desarrollando el talento de nuestra gente.
                                        Somos una empresa diferente, responsable, que apuesta por la estabilidad y cree en la diversidad, en el desarrollo profesional y en el trabajo en equipo.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="trusted-company__box-wrap">
                                <div className="trusted-company__box" style={{ justifyContent: 'center' }}>
                                    <p>Mármoles y piedras</p>
                                </div>
                                <div className="trusted-company__box" style={{ justifyContent: 'center' }}>
                                    <p>Cerámicas y granito</p>
                                </div>
                                <div className="trusted-company__box" style={{ justifyContent: 'center' }}>
                                    <p>Inoxidable y bronce</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="funfact-one">
                <div className="funfact-one__bg" style={{ backgroundImage: `url(${FunFactBG1})` }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <div className="funfact-one__box">
                                <h3>+<span className="odometer" data-count="99">{format_integer_thousands_to_point(Math.round(4500))}</span></h3>
                                <p>Lápidas al año</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="funfact-one__box">
                                <h3><span className="odometer" data-count="90">100</span>%</h3>
                                <p>Clientes satisfechos</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="funfact-one__box">
                                <h3><span className="odometer" data-count="8900">78</span></h3>
                                <p>Colaboración funerarias</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="funfact-one__box">
                                <h3><span className="odometer" data-count="346">97</span></h3>
                                <p>Estilos de lápidas</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="testimonials-one pb-60">
                <div className="container">
                    <div className="block-title text-center">
                        <p>Dicen de nosotros</p>
                        <h2>Testimonios de nuestros clientes</h2>
                    </div>

                    <div className="testimonios_section">

                        <div className="testimonials-one__box">
                            <p>
                                <span>
                                    En momentos difíciles cuando uno pierde a un ser querido, siempre es de agradecer a unos profesionales que se esfuerzan por hacer su trabajo lo mejor posible.
                                    "LÁPIDAS PEREIRO " Gracias de parte de la familia de Purificación Pérez Lago por vuestro excelente trabajo.
                                </span>
                            </p>
                            <div className="testimonials-one__box-info">
                                <h3>Manuel Pena</h3>
                            </div>
                        </div>

                        <div className="testimonials-one__box">
                            <p>
                                <span>
                                    Fantástica atención, Marcos muy enrollado y a la vez muy profesional, te aconseja desde su ya mucha experiencia pero siempre respetando los gustos particulares.
                                    No es pesetero y le gusta hacer bien su trabajo.
                                </span>
                            </p>
                            <div className="testimonials-one__box-info">
                                <h3>Ánxelo Maia</h3>
                            </div>
                        </div>


                        <div className="testimonials-one__box">
                            <p>
                                <span>
                                    En situaciones tan dolorosas, es impagable encontrar gente que se implique tanto. Marcos es una persona comprensiva que interpretó perfectamente nuestros deseos y nos hizo sentir muy bien. Al final todo quedó perfecto. No lo olvidaremos.
                                </span>
                            </p>
                            <div className="testimonials-one__box-info">
                                <h3>Carlota Díaz</h3>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="why-choose">
                <img src="assets/images/shapes/why-choose-shape-1-1_v2.png" className="why-choose__shape-1" alt="" />
                <img src="assets/images/shapes/why-choose-shape-1-2_v2.png" className="why-choose__shape-2" alt="" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="why-choose__image">
                                <p>Larga experiencia en la fabricación de lápidas</p>
                                <picture className="picture_30_years_experience">
                                    <img
                                        loading="lazy"

                                        sizes="(max-width: 992px) 100vw, 992px"
                                        srcSet="
                                            assets/images/resources/lapida-funeraria-para-nicho_v2_poa3s5/lapida-funeraria-para-nicho_v2_poa3s5_c_scale,w_200.webp 200w,
                                            assets/images/resources/lapida-funeraria-para-nicho_v2_poa3s5/lapida-funeraria-para-nicho_v2_poa3s5_c_scale,w_589.webp 589w,
                                            assets/images/resources/lapida-funeraria-para-nicho_v2_poa3s5/lapida-funeraria-para-nicho_v2_poa3s5_c_scale,w_902.webp 902w,
                                            assets/images/resources/lapida-funeraria-para-nicho_v2_poa3s5/lapida-funeraria-para-nicho_v2_poa3s5_c_scale,w_992.webp 992w"
                                        src="assets/images/resources/lapida-funeraria-para-nicho_v2_poa3s5/lapida-funeraria-para-nicho_v2_poa3s5_c_scale,w_992.webp"

                                        alt=""
                                    />
                                </picture>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="why-choose__content">
                                <div className="block-title text-left">
                                    <p>Nuestro hacer</p>
                                    <h2>¿Por qué con Lápidas Pereiro?</h2>
                                </div>
                                <p>Por que llevamos años ofreciendo un servicio profesional, con buena atención y totalmente personalizada a las necesidades de nuestros clientes.</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="why-choose__box">
                                            <h3><i className="fa fa-caret-right"></i>Materiales de calidad</h3>
                                            <p>Nuestras lápidas están hechas con materiales de primera calidad.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="why-choose__box">
                                            <h3><i className="fa fa-caret-right"></i>Instalación insittu</h3>
                                            <p>Nos encargamos de la fabricación y la colocación insittu.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <div className="client-carousel pt-120 pb-120 client-carousel__has-border-top carrucel_cementerios">
                <div className="container">
                    <div className="block-title text-center">
                        <p> Hemos trabajado en los siguientes </p>
                        <h2> Cementerios </h2>
                    </div>
                    <Swiper className="thm-swiper__slider swiper-container custom_slider_tumbas"
                        modules={[Autoplay]}
                        spaceBetween={0}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{ delay: 5000 }}
                        breakpoints={{
                            "376": {
                                "spaceBetween": 30,
                                "slidesPerView": 2
                            },
                            "576": {
                                "spaceBetween": 30,
                                "slidesPerView": 3
                            },
                            "768": {
                                "spaceBetween": 30,
                                "slidesPerView": 3
                            },
                            "992": {
                                "spaceBetween": 30,
                                "slidesPerView": 4
                            },
                            "1200": {
                                "spaceBetween": 30,
                                "slidesPerView": 5
                            }
                        }}>
                        <div className="swiper-wrapper">
                            {data_carrucel_home_lapidas && data_carrucel_home_lapidas.map((carrucel) =>
                                <SwiperSlide key={carrucel.id}>
                                    <div className="swiper-slide item_carrucel_maps" onClick={() => open_url(carrucel.url_google_maps)}>
                                        <img src={`${carrucel.url_image}`} alt={`${carrucel.nombre}`} />
                                        <p>{carrucel.nombre}</p>
                                    </div>
                                </SwiperSlide>
                            )}
                        </div>
                    </Swiper>
                </div>
            </div>

            <section className="call-to-action" style={{ backgroundImage: `url(${ActionBG1})` }}>
                <div className="container">
                    <div className="left-content">
                        <p><span>Calidad</span><span>Rápidez</span><span>Precio asequible</span></p>
                        <h3>Trabajos garantizados al 100%</h3>
                    </div>
                    <div className="right-content">
                        <Link to="#" className="thm-btn">Solicite Presupuesto.</Link>
                    </div>
                </div>
            </section>




        </>
    )
}

export default Main
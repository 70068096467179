import { Link } from 'react-router-dom'
import React from 'react'
import HoraApertura from '../COMMON/HoraApertura';

const Footer = () => {

    const scroll_to_top = (event) => {
        event.preventDefault();
        console.log('scroll to top')
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 300);
    }


    return (
        <>
            <footer className="site-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="footer-widget footer-widget__about">
                                < Link to="/">
                                    <picture>
                                        <source
                                            media="(max-width: 1199px)"
                                            sizes="(max-width: 1200px) 60vw, 720px"
                                            srcSet="
                                        assets/images/logo_full_picture/logo_full_ln7idu_c_scale,w_200.webp 200w,
                                        assets/images/logo_full_picture/logo_full_ln7idu_c_scale,w_720.webp 720w"/>
                                        <img
                                            width="300"
                                            sizes="(max-width: 3500px) 40vw, 1400px"
                                            srcSet="
                                        assets/images/logo_full_picture/logo_full_ln7idu_c_scale,w_480.webp 480w,
                                        assets/images/logo_full_picture/logo_full_ln7idu_c_scale,w_997.webp 997w,
                                        assets/images/logo_full_picture/logo_full_ln7idu_c_scale,w_1400.webp 1400w"
                                            src="assets/images/logo_full_picture/logo_full_ln7idu_c_scale,w_1400.webp"
                                            alt=""
                                            className='logo_footer invert_color'
                                        />
                                    </picture>
                                </Link>
                                <p>
                                    En Grupo Lápidas Pereiro  estamos  dedicados  en la elaboración de lápidas a medida y las adaptamos a las circunstancias de cada cliente.
                                </p>
                                <div className="footer-widget__about-phone">
                                    <i className="pylon-icon-tech-support"></i>
                                    <div className="footer-widget__about-phone-content">
                                        <span>LE ATENDEMOS</span>
                                        <h3>< Link to="tel:+34986295958">+34 986 29 59 58</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 areas_footer">
                            <div className="footer-widget footer-widget__link">
                                <h3 className="footer-widget__title">Áreas</h3>
                                <ul className="list-unstyled footer-widget__link-list">
                                    <li>< Link to="/">Home</Link></li>
                                    <li>< Link to="/lapidas">Lápidas</Link></li>
                                    <li>< Link to="/servicios">Servicios</Link></li>
                                    <li>< Link to="/mascotas">Mascotas</Link></li>
                                    <li>< Link to="/contact">Contacto</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="footer-widget footer-widget__contact">
                                <h3>Contacto</h3>
                                <ul className="list-unstyled footer-widget__contact-list">
                                    <li>
                                        < Link to="/contact"><i className="pylon-icon-email1"></i>info@grupolapidaspereiro.com</Link>
                                    </li>
                                    <li>
                                        <HoraApertura />
                                    </li>
                                    <li>
                                        < Link to="https://goo.gl/maps/MbFgjzKDoWYcAj4A8"><i className="pylon-icon-pin1"></i>Av. Castrelos 85. 36210 Vigo</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="bottom-footer">
                <div className="container" style={{ justifyContent: 'flex-start' }}>
                    <p>© Copyright 2023 - GRUPO LÁPIDAS PEREIRO</p>
                    <p style={{ marginLeft: 10, marginRight: 10 }}>-</p>
                    <p className='politicas'>< Link to="/aviso_legal">Aviso legal</Link></p>
                    <p style={{ marginLeft: 10, marginRight: 10 }}>-</p>
                    <p className='politicas'>< Link to="/politica_privacidad">Política de privacidad</Link></p>
                    <p style={{ marginLeft: 10, marginRight: 10 }}>-</p>
                    <p className='politicas'>< Link to="/cookies">Cookies</Link></p>
                </div>
            </div>
            <Link onClick={scroll_to_top} to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>

        </>
    )
}

export default Footer
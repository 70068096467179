import PHBG1 from '../../../assets/images/backgrounds/background-servicios.webp'
import { Link } from 'react-router-dom'
import React from 'react'

const Testimonial = () => {
    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>

            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${PHBG1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li>/</li>
                        <li><span>Servicios</span></li>
                    </ul>
                    <h2>Servicios</h2>
                </div>
            </section>

            <section className="testimonials-page pt-120 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 ">
                            <div className="testimonials-one__box h-100">
                                <p><span>Realizamos todo tipo de reparaciones de nichos y panteones, según las especificaciones de nuestros clientes.</span></p>
                                <div className="testimonials-one__box-info">
                                    <img src="assets/images/resources/testimonials-1-1.png" alt="" className='imagen_testimonial_services' />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="testimonials-one__box h-100">
                                <p><span>Llevamos a cabo ampliaciones de cementerios con la ayuda de nuestros colaboradores de construcción.</span></p>
                                <div className="testimonials-one__box-info">
                                    <img src="assets/images/resources/testimonials-1-1.png" alt="" className='imagen_testimonial_services' />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="testimonials-one__box h-100">
                                <p><span>Fabricamos e instalamos todo tipo de lápidas para personas y mascotas.</span></p>
                                <div className="testimonials-one__box-info">
                                    <img src="assets/images/resources/testimonials-1-1.png" alt="" className='imagen_testimonial_services' />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="testimonials-one__box h-100">
                                <p><span>Realizamos mantenimientos en cementerios: limpiezas, reparaciones, etc.</span></p>
                                <div className="testimonials-one__box-info">
                                    <img src="assets/images/resources/testimonials-1-1.png" alt="" className='imagen_testimonial_services' />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="testimonials-one__box h-100">
                                <p><span>Llevamos a cabo los diseños de lápidas más exigentes según los requisitos de cada cliente.</span></p>
                                <div className="testimonials-one__box-info">
                                    <img src="assets/images/resources/testimonials-1-1.png" alt="" className='imagen_testimonial_services' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonial
import { Link, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import WOW from 'wowjs'
import HoraApertura from '../COMMON/HoraApertura'

const Navbar = () => {
    const [mobile, setmobile] = useState(false)
    const [homeDrop, sethomeDrop] = useState(false)
    const [headerDrop, setheaderDrop] = useState(false)
    const [aboutDrop, setaboutDrop] = useState(false)
    const [newsDrop, setnewsDrop] = useState(false)
    const [pagesDrop, setpagesDrop] = useState(false)
    const [cardDrop, setcardDrop] = useState(false)
    const [search, setsearch] = useState(false)
    const location = useLocation()
    const path = location.pathname
    const [menu, setmenu] = useState({})
    const activeMenu = () => {
        if (path === "/" || path === "/home-02") {
            setmenu({ home: true })
        } else if (path === "team" || path === "/team-details") {
            setmenu({ about: true })
        } else if (path === "/lapidas" || path === "/service-details") {
            setmenu({ lapidas: true })
        } else if (path === "/work" || path === "/loan-calculator" || path === "/credit-card" || path === "/faqs" || path === "/testimonial") {
            setmenu({ pages: true })
        } else if (path === "/shops" || path === "/shopdetails") {
            setmenu({ shop: true })
        } else if (path === "/news-main" || path === "/news-details") {
            setmenu({ news: true })
        }
        else if (path === "/contact") {
            setmenu({ contact: true })
        } else if (path === "/servicios") {
            setmenu({ servicios: true })
        } else if (path === "/mascotas") {
            setmenu({ mascotas: true })
        } else {
            setmenu({ home: true })
        }
    }


    const [sticky, setSticky] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
        new WOW.WOW({
            live: false
        }).init();
        activeMenu()
    }, [path]);
    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }
    return (
        <>
            <div className="page-wrapper">
                <header className="main-header">
                    <div className="topbar">
                        <div className="container">
                            <div className="topbar__left">
                                <Link to="/">ÁREA CLIENTES</Link>
                                <Link to="https://workteam.grupolapidaspereiro.com">INTRANET</Link>
                            </div>
                            <div className="topbar__right">
                                <Link to="/contact"><i className="pylon-icon-email1"></i>info@grupolapidaspereiro.com</Link>
                                <HoraApertura />
                            </div>
                        </div>
                    </div>
                    <nav className={`main-menu ${sticky && "stricky-header stricked-menu stricky-fixed"}`}>
                        <div className="container">
                            <div className="logo-box">
                                <Link to="/" aria-label="logo image">
                                    <picture>
                                        <source
                                            media="(max-width: 600px)"
                                            sizes="(max-width: 600px) 100vw, 600px"
                                            srcSet="
                                        assets/images/logo_full_picture/logo_lapidas_pereiro_zudvxf_c_scale,w_200.webp 200w,
                                        assets/images/logo_full_picture/logo_lapidas_pereiro_zudvxf_c_scale,w_600.webp 600w"/>
                                        <img
                                            sizes="(max-width: 600px) 100vw, 600px"
                                            srcSet="
                                    assets/images/logo_full_picture/logo_lapidas_pereiro_zudvxf_c_scale,w_200.webp 200w,
                                    assets/images/logo_full_picture/logo_lapidas_pereiro_zudvxf_c_scale,w_600.webp 600w"
                                            src="assets/images/logo_full_picture/logo_lapidas_pereiro_zudvxf_c_scale,w_600.webp"
                                            alt=""
                                            className='logo_header'
                                        />
                                    </picture>
                                </Link>
                                <span onClick={() => setmobile(true)} className="fa fa-bars mobile-nav__toggler"></span>
                            </div>
                            <ul className="main-menu__list">
                                <li className={`dropdown ${menu.home && "current"}`}>
                                    <Link to="/">
                                        <img className="logo_icons_header" src="assets/images/icons_webp_header/head_home.webp" height="35" alt="" />
                                        Home
                                    </Link>
                                </li>
                                <li className={`dropdown ${menu.lapidas && "current"}`}>
                                    <Link to="/lapidas">
                                        <img className="logo_icons_header" src="assets/images/icons_webp_header/head_lapidas.webp" height="35" alt="" />
                                        Lápidas
                                    </Link>
                                </li>
                                <li className={`dropdown ${menu.servicios && "current"}`}>
                                    <Link to="/servicios">
                                        <img className="logo_icons_header" src="assets/images/icons_webp_header/head_servicios.webp" height="35" alt="" />
                                        Servicios
                                    </Link>
                                </li>
                                <li className={`dropdown ${menu.mascotas && "current"}`}>
                                    <Link to="/mascotas">
                                        <img className="logo_icons_header" src="assets/images/icons_webp_header/head_mascotas.webp" height="35" alt="" />
                                        Mascotas
                                    </Link>
                                </li>
                                <li className={`dropdown ${menu.contact && "current"}`}>
                                    <Link to="/contact">
                                        <img className="logo_icons_header" src="assets/images/icons_webp_header/head_contacto.webp" height="35" alt="" />
                                        Contacto
                                    </Link>
                                </li>
                            </ul>

                            <div className="main-header__info">
                                <div className="main-header__info-phone">
                                    <i className="pylon-icon-tech-support"></i>
                                    <div className="main-header__info-phone-content">
                                        <span>LE ATENDEMOS</span>
                                        <h3><Link to="tel:+34986295958">+34 986 29 59 58</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
            </div>


            <div className={`mobile-nav__wrapper ${mobile ? "expanded" : ""}`}>
                <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setmobile(false)}></div>
                <div className="mobile-nav__content">
                    <span className="mobile-nav__close mobile-nav__toggler" onClick={() => setmobile(false)}><i className="far fa-times"></i></span>

                    <div className="logo-box">
                        < Link to="/" aria-label="logo image">
                            <picture>
                                <source
                                    media="(max-width: 1199px)"
                                    sizes="(max-width: 1200px) 60vw, 720px"
                                    srcSet="
                                        assets/images/logo_full_picture/logo_full_ln7idu_c_scale,w_200.webp 200w,
                                        assets/images/logo_full_picture/logo_full_ln7idu_c_scale,w_720.webp 720w"/>
                                <img
                                    width="300"
                                    sizes="(max-width: 3500px) 40vw, 1400px"
                                    srcSet="
                                        assets/images/logo_full_picture/logo_full_ln7idu_c_scale,w_480.webp 480w,
                                        assets/images/logo_full_picture/logo_full_ln7idu_c_scale,w_997.webp 997w,
                                        assets/images/logo_full_picture/logo_full_ln7idu_c_scale,w_1400.webp 1400w"
                                    src="assets/images/logo_full_picture/logo_full_ln7idu_c_scale,w_1400.webp"
                                    alt=""
                                    className='logo_header_menu invert_color'
                                />
                            </picture>
                        </Link>
                    </div>
                    <div className="mobile-nav__container">
                        <ul className="main-menu__list">
                            <li className="current">
                                <Link to="/" onClick={() => setmobile(false)}>Home</Link>
                            </li>
                            <li>
                                <Link to="/lapidas" onClick={() => setmobile(false)}>Lápidas</Link>
                            </li>
                            <li>
                                <Link to="/servicios" onClick={() => setmobile(false)}>Servicios</Link>
                            </li>
                            <li>
                                <Link to="/mascotas" onClick={() => setmobile(false)}>Mascotas</Link>
                            </li>
                            <li>
                                <Link to="/contact" onClick={() => setmobile(false)}>Contacto</Link>
                            </li>
                        </ul>
                    </div>

                    <ul className="mobile-nav__contact list-unstyled">
                        <li>
                            <i className="pylon-icon-email1"></i>
                            < Link to="/contact">info@grupolapidaspereiro.com</Link>
                        </li>
                        <li>
                            <i className="pylon-icon-telephone"></i>
                            < Link to="tel:+34986295958">+34 986 29 59 58</Link>
                        </li>
                        <li>
                            <i className="pylon-icon-assets"></i>
                            <Link to="https://clientes.grupolapidaspereiro.com">ÁREA CLIENTES</Link>
                        </li>
                    </ul>
                </div>
            </div>

            <div className={`search-popup ${search && "active"}`}>
                <div className="search-popup__overlay search-toggler" onClick={() => setsearch(false)}></div>
                <div className="search-popup__content">
                    <form action="#">
                        <label htmlFor="search" className="sr-only">search here</label>
                        <input type="text" id="search" placeholder="Search Here..." />
                        <button type="submit" aria-label="search submit" className="thm-btn">
                            <i className="fa fa-search"></i>
                        </button>
                    </form>
                </div>
            </div>

        </>
    )
}

export default Navbar
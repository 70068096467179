import PHBG1 from '../../assets/images/backgrounds/background_contacto.webp'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { lapidas_api } from '../../__api-resolvers__/lapidas-api'

const Contact = () => {

    const [msg_valid, setmsg_valid] = useState(null);
    const [connecting_api, setconnecting_api] = useState(false);

    const form_subimted = async (event) => {
        event.preventDefault();

        const name = event.target.name.value;
        const email = event.target.email.value;
        const phone = event.target.phone.value;
        const subject = event.target.subject.value;
        const message = event.target.message.value;

        console.log('Enviando mensaje con values ', name, ' ', email, ' ', phone, ' ', subject, ' ', message)
        const body = `<div>Nombre: ${name}</div><br><div>Email: ${email}</div><br><div>Teléfono: ${!!phone ? phone : 'Ninguno'}</div><br><div>Asunto: ${subject}</div><br><div>Mensaje: ${!!message ? message : 'Ninguno'}</div>`;

        try {
            setconnecting_api(true);


            const body_inform_client = `<div>Hola ${name} ,</div><br><div>Hemos recibido su mensaje correctamente. A continuación le muestro el contenido del mismo:</div><br>`
                + `<div style="border-left: 1px solid #ccc;padding-left: 5px;font-style: italic;"> ${body} </div><br>`
                + `<div> Normalmente damos respuesta a las solicitudes que nos llegan por la web en cuestión de minutos, pero es posible que se pueda demorar.</div><br>`
                + `<div> Agradecemos se haya puesto en contacto con Grupo Lápidas Pereiro.</div><br>`
                + `<div> Atentamente Grupo Lápidas Pereiro.</div><br>`;

            const resultado_cliente = await lapidas_api.sendNewEmail(
                name,
                email,
                `Su mensaje ha sido recibido :)`,
                body_inform_client,
                '',
                '',
                'Grupo Lápidas Pereiro',
                'info@grupolapidaspereiro.com',
                'info@grupolapidaspereiro.com',
                'Grupo Lápidas Pereiro',
                null,
                null,
                null,
                'https://hahref.es/uploads/1685413156577logo_email_lapidas_pereiro.png',
                'https://grupolapidaspereiro.com',
                null,
                null,
                'info@grupolapidaspereiro.com'
            )
            console.log('result client', resultado_cliente)

            setmsg_valid({ value: true });
            event.target.reset();
            setconnecting_api(false);
        } catch (err) {
            setmsg_valid({ value: false });
            setconnecting_api(false);
            console.log('Error ', err)
        }


    }
    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>

            <section className="page-header">
                <div className="page-header__bg background_image_services" style={{ backgroundImage: `url(${PHBG1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li>/</li>
                        <li><span>Contacto</span></li>
                    </ul>
                    <h2>Contacto</h2>
                </div>
            </section>
            <section className="contact-one">
                <div className="container">
                    <div className="block-title text-center">
                        <p>Contacta con nosotros</p>
                        <h2>Envíenos su Consulta.</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="contact-one__content">
                                <p>Puedes contactar con nosotros a traves del siguiente formulario, o bien con los datos de contacto siguientes.</p>
                                <div className="contact-one__box">
                                    <i className="pylon-icon-telephone"></i>
                                    <div className="contact-one__box-content">
                                        <h4>Llámanos</h4>
                                        <Link to="tel:+34986295958">+34 986 29 59 58</Link>
                                    </div>
                                </div>
                                <div className="contact-one__box">
                                    <i className="pylon-icon-email1"></i>
                                    <div className="contact-one__box-content">
                                        <h4>Contacta por E-mail</h4>
                                        <Link to="/contact">info@grupolapidaspereiro.com</Link>
                                    </div>
                                </div>
                                <div className="contact-one__box">
                                    <i className="pylon-icon-pin1"></i>
                                    <div className="contact-one__box-content">
                                        <h4>Visita nuestras instalaciones</h4>
                                        <Link to="https://goo.gl/maps/MbFgjzKDoWYcAj4A8">Av. Castrelos 85. 36210 Vigo</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <form method="post" className="contact-one__form" onSubmit={form_subimted}>
                                <div className="row low-gutters">
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Tu nombre" name="name" required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="email" placeholder="E-mail" name="email" required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Teléfono" name="phone" />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Asunto" name="subject" required />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea name="message" placeholder="Escribe un mensaje"></textarea>
                                        {msg_valid &&
                                            <>
                                                {msg_valid.value ?
                                                    <p className="message-ok">Tu mensaje ha sido enviado, gracias.</p>
                                                    :
                                                    <p className="message-error">Tu mensaje no ha podido ser enviado, inténtelo de nuevo más tarde.</p>
                                                }
                                            </>
                                        }
                                        {!msg_valid &&
                                            <button className="thm-btn" type="submit" disabled={connecting_api}>Enviar</button>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <div className="google-map__home-two">
                <iframe title="template google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2955.2234442831596!2d-8.731828499999999!3d42.209668799999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2589e5e127744d%3A0xfbcdfb0edbb9da7e!2zTMOhcGlkYXMgUGVyZWlyw7M!5e0!3m2!1ses!2sec!4v1677188789121!5m2!1ses!2sec" className="map__home-two" allowFullScreen></iframe>
            </div>
        </>
    )
}

export default Contact
import PHBG1 from '../../../assets/images/services/mascotas_background.webp'
import SSBAR1 from '../../../assets/images/services/service-sidebar-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import { useMounted } from '../../../hooks/use-mounted'
import { lapidas_api } from '../../../__api-resolvers__/lapidas-api'
import { useLocation } from "react-router-dom";
import { sort_object_of_objects_asc } from '../../../helpers/global_helpers'

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const MascotasDetails = () => {
    let query = useQuery();
    const isMounted = useMounted();

    const [activeFaq, setActiveFaq] = useState({ id: null });

    const [data_mascotas, setdata_mascotas] = useState(null);

    return (
        <>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg background_image_services" style={{ backgroundImage: `url(${PHBG1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li>/</li>
                        <li>Mascotas</li>
                    </ul>
                    <h2>Mascotas</h2>
                </div>
            </section>

            <section className="service-details faq-one" style={{ paddingTop: 40 }}>
                {data_mascotas ?
                    <div className="progress">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '75%' }}></div>
                    </div>
                    :
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details__content">
                                    <h3>Lápidas de Mascotas</h3>
                                    <p>Sentimos un profundo respeto por las mascotas. Por eso queremos que se les pueda dar el adiós más entrañable
                                        con el ánimo de conectar dos cuestiones tan vitales como es la unión entre el ser humano y su mascota y la separación motivada por la muerte.
                                        Nuestras lápidas  te ayudarán a decirle adiós como tú desees.
                                        Buscamos solucionar la despedida física y la despedida emocional.
                                    </p>
                                    <p>Son más que mascotas. Los que nos esperan en la puerta de casa y quieren siempre jugar.
                                        Son buenos compañeros, con los que pasamos ratos de ocio y relax. Viéndoles jugar, ir y venir, nuestra cabeza descansa, paseamos, pensamos… ¡y ellos sin darse cuenta de todo el bien que nos hacen!
                                        Y nos gusta recordar a nuestro perro, a nuestro gato, periquito, cobaya, hurón, serpiente… los demás no lo entederán…</p>
                                    <p>Los tipos de lápidas para las mascotas serian de todo tipo dependiendo de lo que quiera la familia  cerámicas, grabaciones o solo códigos qr</p>
                                    <div className="row">
                                        <div className="col-xl-4">
                                            <img src="assets/images/services/mascotas_image_1.webp" alt="" className="service-details__content-image img-fluid" />
                                        </div>
                                        <div className="col-xl-8 contenedor_caracteristicas_mascotas">
                                            <h4>Características Generales</h4>
                                            <p>Nuestras lápidas de mascotas se tienen las siguientes características:</p>
                                            <ul className="list-unstyled about-two__list service-details__list">
                                                <li>
                                                    <i className="fa fa-check-circle"></i>
                                                    Maquetamos con la huella de la mascota
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-circle"></i>
                                                    Realizamos un diseño con la imagen de la mascota
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-circle"></i>
                                                    El tamaño y la forma son totalmente personalizados
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-circle"></i>
                                                    Hay un amplio abanico de materiales y acabados
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* <ul id="accordion" className="mb-0 wow fadeInUp list-unstyled" data-wow-duration="1500ms">
                                        <li>
                                            <h2 className={`para-title ${activeFaq.a && "active"}`} >
                                                <span className="collapsed" role="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                                                    onClick={() => setActiveFaq(activeFaq.a ? { a: false } : { a: true })}
                                                >
                                                    {activeFaq.a ? <i className="far fa-minus"></i> : <i className="far fa-plus"></i>}
                                                    ¿Pregunta número 1?
                                                </span>
                                            </h2>
                                            <div id="collapseTwo" className="collapse" role="button" aria-labelledby="collapseTwo" data-parent="#accordion">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                                </p>
                                            </div>
                                        </li>
                                        <li className="active">
                                            <h2 className={`para-title ${activeFaq.b && "active"}`}>
                                                <span className="collapsed" role="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"
                                                    onClick={() => setActiveFaq(activeFaq.b ? { b: false } : { b: true })}
                                                >
                                                    {activeFaq.b ? <i className="far fa-minus"></i> : <i className="far fa-plus"></i>}
                                                    ¿Pregunta número 2?
                                                </span>
                                            </h2>
                                            <div id="collapseOne" className="collapse show" aria-labelledby="collapseOne" data-parent="#accordion">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <h2 className={`para-title ${activeFaq.c && "active"}`}>
                                                <span className="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" role="button" aria-controls="collapseThree"
                                                    onClick={() => setActiveFaq(activeFaq.c ? { c: false } : { c: true })}
                                                >
                                                    {activeFaq.c ? <i className="far fa-minus"></i> : <i className="far fa-plus"></i>}
                                                    ¿Pregunta número 3?
                                                </span>
                                            </h2>
                                            <div id="collapseThree" className="collapse" aria-labelledby="collapseThree" data-parent="#accordion">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                                </p>
                                            </div>
                                        </li>
                                    </ul> */}

                                </div>
                            </div>
                        </div>
                    </div>
                }
            </section>
        </>
    )
}

export default MascotasDetails
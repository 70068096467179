import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import Navbar from '../components/Navbar/Main'
import Footer from '../components/Footer/Main'
import Home from '../components/Home/Main'
import Home2 from '../components/Home2/Main'
import Team from '../components/About/Team/Main'
import TeamDetails from '../components/About/TeamDetails/Main'
import Service from '../components/Services/Service/Main'
import ServiceDetails from '../components/Services/ServiceDetails/Main'
import NewsMain from '../components/News/NewsMain/Main'
import NewsDetails from '../components/News/NewsDetails/Main'
import Work from '../components/Pages/Work/Main'
import LoanCalculator from '../components/Pages/LoanCalculator/Main'
import CreditCard from '../components/Credit/CreditCard/Main'
import CreditCardDetails from '../components/Credit/CreditCardDetails/Main'
import FAQ from '../components/Pages/FAQ/Main'
import Testimonials from '../components/Pages/Testimonial/Main'
import Contact from '../components/Contact/Main'
import AvisoLegal from '../components/News/AvisoLegal/Main'
import PoliticaPrivacidad from '../components/News/PoliticaPrivacidad/Main'
import CookiesAgreement from '../components/News/Cookies/Main'


const Routing = () => {
  const [homepage, sethomepage] = useState(false)
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/home-02") {
      sethomepage(false)
    } else {
      sethomepage(true)
    }
  }, [location])

  return (
    <>
      {homepage && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home-02" element={<Home2 />} />
        <Route path="/team" element={<Team />} />
        <Route path="/team-details" element={<TeamDetails />} />
        <Route path="/lapidas" element={<Service />} />
        <Route path="/service-details" element={<ServiceDetails />} />
        <Route path="/mascotas" element={<NewsMain />} />
        <Route path="/news-details" element={<NewsDetails />} />
        <Route path="/work" element={<Work />} />
        <Route path="/loan-calculator" element={< LoanCalculator />} />
        <Route path="/credit-card" element={< CreditCard />} />
        <Route path="/credit-card-details" element={< CreditCardDetails />} />
        <Route path="/faqs" element={<FAQ />} />
        <Route path="/servicios" element={<Testimonials />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/aviso_legal" element={<AvisoLegal />} />
        <Route path="/politica_privacidad" element={<PoliticaPrivacidad />} />
        <Route path="/cookies" element={<CookiesAgreement />} />
      </Routes>
      <Footer />
    </>
  )
}

export default Routing
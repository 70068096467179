import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from "moment-timezone";

const HoraApertura = () => {

    const [nombre_colocar, setnombre_colocar] = useState(null);


    useEffect(() => {

        const now = moment.tz("Europe/Madrid");
        const currentMonth = now.month();
        let schedule;
        if (currentMonth === 6 || currentMonth === 7) {
            // para julio y agosto
            schedule = "Lunes a Viernes de 09:00h a 14:00h";
        } else {
            schedule = "Lunes a Viernes de 09:00h a 13:00h y de 16:00h a 19:00h";
        }

        setnombre_colocar(schedule);

    }, [])



    if (!nombre_colocar) {
        return (<Fragment></Fragment>)
    }

    return (
        <Link to="#"><i className="pylon-icon-clock2"></i>{nombre_colocar}</Link>
    )

}

export default HoraApertura

/**
 * Archivo con helpers globales con funciones que no dependen de ningun framework y se usan regularmente en cualquier proyecto
 */

import axios from "axios";
import fileDownload from "js-file-download";
import * as mime from 'react-native-mime-types';

export const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
        .then((res) => { return res.arrayBuffer(); })
        .then((buf) => { return new File([buf], filename, { type: mimeType }); })
    );
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const sortByKey = (array, key) => {
    return array.sort((a, b) => {
        var x = a[key]; var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}

export const sortByKeyDesc = (array, key) => {
    return array.sort((a, b) => {
        var x = a[key]; var y = b[key];
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
}

export const sort_object_of_objects_desc = (data, attr) => {
    var arr = [];
    for (var prop in data) {
        if (data.hasOwnProperty(prop)) {
            var obj = {};
            obj[prop] = data[prop];
            obj.tempSortName = data[prop][attr];
            arr.push(obj);
        }
    }

    arr.sort(function (a, b) {
        var at = a.tempSortName,
            bt = b.tempSortName;
        return at < bt ? 1 : (at > bt ? -1 : 0);
    });

    var result = [];
    for (var i = 0, l = arr.length; i < l; i++) {
        var obj = arr[i];
        delete obj.tempSortName;
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                var id = prop;
            }
        }
        var item = obj[id];
        result.push(item);
    }
    return result;
}

export const sort_object_of_objects_asc = (data, attr) => {
    var arr = [];
    for (var prop in data) {
        if (data.hasOwnProperty(prop)) {
            var obj = {};
            obj[prop] = data[prop];
            obj.tempSortName = data[prop][attr];
            arr.push(obj);
        }
    }

    arr.sort(function (a, b) {
        var at = a.tempSortName,
            bt = b.tempSortName;
        return at > bt ? 1 : (at < bt ? -1 : 0);
    });

    var result = [];
    for (var i = 0, l = arr.length; i < l; i++) {
        var obj = arr[i];
        delete obj.tempSortName;
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                var id = prop;
            }
        }
        var item = obj[id];
        result.push(item);
    }
    return result;
}


export const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}

export const first_array_contained_second_strings = (arr, target) => {
    let checker = (arr, target) => target.every(v => arr.includes(v));
    return checker;
}

export const isValidHttpUrl = (string) => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export const twoDigitsOnly = (value) => /^\d+(\.\d{0,2})?$/.test(value) || value === undefined || value.length === 0;

export const normalize_string = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const normalize_string_remove_spaces = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[\[\]]/g, "").replace(/[\(\)]/g, "").replace(/ /g, "_");

export const remove_points_string = (str) => str.replace(/\./g, '_');

export const renameFile = (originalFile, newName) => {
    console.log("renombrando file con ", originalFile, " para newName ", newName)
    const extension_file = originalFile.name.split(".").pop();
    return new File(
        [originalFile],
        `${newName.replace(/ /g, "_")}.${extension_file}`,
        {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        }
    );
}

export const basename_path_file = (path) => {
    let base = new String(path).substring(path.lastIndexOf('/') + 1);
    if (base.lastIndexOf(".") != -1)
        base = base.substring(0, base.lastIndexOf("."));
    return base;
}

export const set_path_File = (originalFile, newName) => {
    const extension_file = originalFile.name.split(".").pop();
    return new File(
        [originalFile],
        `${originalFile.name}`,
        {
            path: originalFile.name,
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        }
    );
}

export const dataURLtoFile = (dataurl, filename) => {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export const arrayBufferToFileObject = (Arraybuffer, Filetype, fileName) => {
    let binary = '';
    const bytes = new Uint8Array(Arraybuffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    const file = window.btoa(binary);
    let mime_type_final_try = mime.contentType(Filetype);
    mime_type_final_try = mime_type_final_try ? mime_type_final_try : Filetype;

    const mimType = Filetype === 'pdf' ? 'application/pdf' : Filetype === 'xlsx' ? 'application/xlsx' :
        Filetype === 'pptx' ? 'application/pptx' : Filetype === 'csv' ? 'application/csv' : Filetype === 'docx' ? 'application/docx' :
            Filetype === 'jpg' ? 'image/jpg' : Filetype === 'jpeg' ? 'image/jpeg' : Filetype === 'png' ? 'image/png' :
                Filetype === 'mp4' ? 'video/mp4' : mime_type_final_try;

    const url = `data:${mimType};base64,` + file;
    //console.log(" url ", url)
    const file_objetct = dataURLtoFile(url, fileName);

    return file_objetct;
}



export const formatter_money = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const encodeParentesis = (string) => {
    return string.replace(/\(/g, '%28').replace(/\)/g, '%29')
}

export const formatNumberFixed = (num, fixed) => {
    var decimalPart;

    var array = Math.floor(num).toString().split('');
    var index = -3;
    while (array.length + index > 0) {
        array.splice(index, 0, '.');
        index -= 4;
    }

    if (fixed > 0) {
        decimalPart = num.toFixed(fixed).split(".")[1];
        return array.join('') + "," + decimalPart;
    }
    return array.join('');
};

export const addQueryParam = (router, param) => {
    router.replace({
        query: { ...router.query, ...param },
    });
};


export const removeQueryParam = (router, param) => {
    const { pathname, query } = router;
    const params = new URLSearchParams(query);
    params.delete(param);
    router.replace(
        { pathname, query: params.toString() },
        undefined,
        { shallow: true }
    );
};


export const paginate_array = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
}


export const getPageStart = (pageSize, pageNr) => {
    return pageSize * pageNr;
};

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const pad = (num, size) => {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

export const numberFormatSpainComma = (x) => {
    return x.toLocaleString('es-ES')
}

/**
 * -------------------------------------------------------------------- Seccion de ayuda para download chunks --------------------------------------------------------------------
 */
export const asyncPool = async (concurrency, iterable, iteratorFn) => {
    const ret = []; // Store all asynchronous tasks
    const executing = new Set(); // Stores executing asynchronous tasks

    await iterable.reduce(async (promise, item) => {
        await promise;
        // Call the iteratorFn function to create an asynchronous task
        const p = Promise.resolve().then(() => iteratorFn(item, iterable));

        ret.push(p); // save new async task
        executing.add(p); // Save an executing asynchronous task

        const clean = () => executing.delete(p);
        p.then(clean).catch(clean);
        if (executing.size >= concurrency) {
            // Wait for faster task execution to complete
            await Promise.race(executing);
        }

    }, Promise.resolve());

    return Promise.all(ret);
}


export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
            reject(new Error(error.message));
        };
    });
}

/**
 * Mejorado cambiamos el ArrayBuffer de Uint8Array por concatenar en blob que es un proceso que ya no esta limitado por javascript
 */
export const concatenate = (arrays, name) => {
    if (!arrays.length) return null;
    console.log(" porceso contatenate con name ", name);

    let mime_result = mime.contentType(name.split(".").pop());
    console.log(" mime_result ", mime_result)

    return new Blob(arrays, {
        type: mime_result
    });
}


export const saveAsDownload = ({ name, buffers }) => {
    console.log(" saveAs con name ", name, " buffers ", buffers)
    fileDownload(buffers, name);
}

export const saveAsDownloadPreview = ({ name, buffers }) => {
    console.log(" saveAs con name ", name, " buffers ", buffers)

    const blobUrl = URL.createObjectURL(buffers);
    console.log(" blobUrl ", blobUrl)
    setTimeout(() => {
        window.open(blobUrl, "_blank");
    }, 500);
}

export const array_buffer_url_object = ({ name, buffers }) => {
    //console.log(" blob name ", name, " buffers ", buffers)
    const blobUrl = URL.createObjectURL(buffers);
    return blobUrl;
}


export const format_integer_thousands_to_point = (number) => {
    return new Intl.NumberFormat('de-DE', { maximumFractionDigits: 0, }).format(number)
}
import PHBG1 from '../../../assets/images/backgrounds/page-header-bg-1-1_blank.jpg'
import { Link } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import { useMounted } from '../../../hooks/use-mounted'
import { lapidas_api } from '../../../__api-resolvers__/lapidas-api'
import { useLocation } from "react-router-dom";
import { sort_object_of_objects_asc } from '../../../helpers/global_helpers'

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ServiceDetails = () => {
    let query = useQuery();
    const isMounted = useMounted();

    const [activeFaq, setActiveFaq] = useState({ id: null });

    const [data_lapidas, setdata_lapidas] = useState(null);
    const [data_lapida_actual, setdata_lapida_actual] = useState(null);

    const getDataFromSystem = useCallback(async () => {
        try {
            console.log(' uuid ', query.get("lapida"))
            const data = await lapidas_api.getContentLapidas();
            console.log("data de lapidas ", data);

            if (isMounted()) {
                setdata_lapidas(data);
                setdata_lapida_actual(data.find((v) => v.uuid_servicio.localeCompare(query.get("lapida")) === 0))
            }

        } catch (err) {
            console.error(err);
        }
    }, [isMounted, query]);

    useEffect(() => {
        getDataFromSystem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    return (
        <>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg background_image_services" style={{ backgroundImage: `url(${(data_lapida_actual && data_lapida_actual.image_background) ? data_lapida_actual.image_background : PHBG1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li>/</li>
                        <li><Link to="/lapidas">Lápidas</Link></li>
                    </ul>
                    <h2>{data_lapida_actual ? data_lapida_actual.nombre : ''}</h2>
                </div>
            </section>

            <section className="service-details faq-one">
                {!data_lapida_actual ?
                    <div className="progress">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '75%' }}></div>
                    </div>
                    :
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="service-sidebar">
                                    <div className="service-sidebar__category">
                                        <h3>Tipos de lápidas</h3>
                                        <ul className="list-unstyled service-sidebar__category-list">
                                            {sort_object_of_objects_asc(data_lapidas, 'id').map((lapida) =>
                                                <li key={lapida.id} className={lapida.uuid_servicio.localeCompare(query.get("lapida")) === 0 ? 'seleccionado' : ''}>
                                                    <Link to={`/service-details?lapida=${lapida.uuid_servicio}`}>{lapida.nombre}</Link>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="service-sidebar__call">
                                        <div className="service-sidebar__call-bg" style={{ backgroundColor: '#002e97' }}></div>
                                        <i className="pylon-icon-tech-support"></i>
                                        <h3><Link to="tel:+34986295958">+34 986 29 59 58</Link></h3>
                                        <p>
                                            Si tiene cualquier duda o necesita asesoramiento, no dude en contactar con nosotros.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="service-details__content">
                                    <div className="service-details__image">
                                        <img src={data_lapida_actual.image_more_info} width="770" height="470" className="img-fluid image_lapida_more_info" alt="" />
                                    </div>
                                    <h3>{data_lapida_actual.nombre}</h3>
                                    {data_lapida_actual.contenido.split('. ').map((v, k) => <p key={k}>{v}</p>)}

                                    {/* <div className="row">
                                        <div className="col-xl-6">
                                            <img src="assets/images/services/service-d-1-2.jpg" alt="" className="service-details__content-image img-fluid" />
                                        </div>
                                        <div className="col-xl-6">
                                            <h4>Loan Requirements</h4>
                                            <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                                            <ul className="list-unstyled about-two__list service-details__list">
                                                <li>
                                                    <i className="fa fa-check-circle"></i>
                                                    Refresing to get such a personal touch
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-circle"></i>
                                                    Duis aute irure dolor in in voluptate
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-circle"></i>
                                                    Velit esse cillum eu fugiat pariatur
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-circle"></i>
                                                    Duis aute irure dolor in in voluptate
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <ul id="accordion" className="mb-0 wow fadeInUp list-unstyled" data-wow-duration="1500ms">
                                        {data_lapida_actual.preguntas_content.map((pregunta) =>
                                            <li
                                                key={pregunta.id}
                                            >
                                                <h2
                                                    className={`para-title ${(activeFaq.id && (activeFaq.id === pregunta.id)) ? "active" : ""}`}
                                                >
                                                    <span
                                                        className="collapsed" role="button" data-toggle="collapse" data-target={`#collapse_${pregunta.id}`}
                                                        aria-expanded="false"
                                                        aria-controls={`collapse_${pregunta.id}`}
                                                        onClick={() => {
                                                            if (pregunta.id === activeFaq.id) {
                                                                setActiveFaq({ id: null })
                                                            } else {
                                                                setActiveFaq({ id: pregunta.id })
                                                            }
                                                        }}
                                                    >
                                                        {(activeFaq.id && (activeFaq.id === pregunta.id)) ? <i className="far fa-minus"></i> : <i className="far fa-plus"></i>}
                                                        {pregunta.nombre_pregunta}
                                                    </span>
                                                </h2>
                                                <div id={`collapse_${pregunta.id}`} className="collapse" role="button" aria-labelledby={`collapse_${pregunta.id}`} data-parent="#accordion">
                                                    <p>
                                                        {pregunta.respuesta_pregunta}
                                                    </p>
                                                </div>
                                            </li>
                                        )}
                                    </ul> */}

                                </div>
                            </div>
                        </div>
                    </div>
                }
            </section>
        </>
    )
}

export default ServiceDetails